/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const black = '#333'
export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          background: '#fdfffe',
          surface: '#fdfffe',
          primary: '#00CC33',
          success: '#00CC33',
          warning: '#ffcc00',
          error: '#CC0033',
          "on-background": black,
          "on-surface": black,
          "on-primary": black,
          "on-secondary": black,
          "on-success": black,
          "on-info": black,
          "on-warning": black,
          "on-error": black,
        },
        dark: false,
        variables: {},
      },
      // todo dark mode
    },
  },
})
