import {useChartOrderStore} from "@/orderbuild.js";


const OHLC_START = new Date(1231027200*1000)  // Sunday January 4th, 2009 just before Bitcoin Genesis


export function nearestOhlcStart(time, periodSeconds=null) {
    if (periodSeconds===null)
        periodSeconds = useChartOrderStore().intervalSecs
    return Math.round((time-OHLC_START) / periodSeconds) * periodSeconds + OHLC_START
}

export function pointsToOhlcStart(points) {
    return points === null ? null : points.map((p) => {
        return {time: nearestOhlcStart(p.time), price: p.price}
    })
}
