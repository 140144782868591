<template>
</template>

<script setup>

const importUrl = 'https://js-na1.hs-scripts.com/46028804.js'

const viteWsUrl = import.meta.env.VITE_WS_URL;

if (viteWsUrl.indexOf('localhost') === -1) {
    console.log('loading HubSpot')
    let script = document.createElement('script')
    script.setAttribute('src', importUrl)
    document.head.appendChild(script)
}

</script>
